<template>
  <div class="home">
    <news-flow class="news-flow" />
    <div class="aside">
      <promotion />
    </div>
  </div>
</template>

<script>
import NewsFlow from '@/components/common/NewsFlow.vue';
import Promotion from '../components/common/Promotion.vue';

export default {
  name: 'Home',
  components: {
    NewsFlow,
    Promotion,
  },
};

</script>

<style lang="scss" scoped>
.home {
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  * {
    margin: 0 3px 0 3px;
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
  .news-flow {
    // flex-basis: 2;
    flex-grow: 2;
  }

  .aside {
    // flex-basis: 1;
    flex-grow: 1;
  }
}
</style>
