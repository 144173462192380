<template>
  <aside id="asidePromotion">
    <h2>{{ $t('headers.merchandise') }}</h2>
    <p>
      {{ $t('view.store.merchandise-text') }}:
      <a
        href="https://begravd.myspreadshop.se"
        target="blank"
      >
        begravd.myspreadshop.se
      </a>
    </p>
    <a href="https://begravd.myspreadshop.se" target="blank">
      <img
        src="@/assets/images/merchandise/begravd_t-shirt_bones.png"
        alt="Begravd t-shirt">
    </a>
  </aside>
</template>

<script>
export default {
  name: 'Promotion',
};
</script>

<style lang="scss" scoped>
img {
  max-width: 90%;
}
</style>
