<template>
  <section id="sectionNews">
    <h2>{{ $t('headers.news') }} & {{ $t('headers.events') }}</h2>
    <iframe
      id="newsSectionMobile"
      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FBegravdtheband%2F&tabs=timeline%2Cevents&width=350&height=350&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=957160627654802"
      width="350"
      height="350"
      style="border:none;overflow:hidden"
      scrolling="no"
      frameborder="0"
      allowfullscreen="true"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      >
    </iframe>
    <iframe
      id="newsSectionBig"
      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FBegravdtheband%2F&tabs=timeline%2Cevents&width=400&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=957160627654802"
      width="400"
      height="500"
      style="border:none;overflow:hidden"
      scrolling="no"
      frameborder="0"
      allowfullscreen="true"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      >
    </iframe>
  </section>
</template>

<script>
export default {
  name: 'NewsFlow',
};
</script>

<style lang="scss" scoped>
#newsSectionBig{
  display: none;
}
@media (min-width: 600px) {
  #newsSectionBig{
    display: block;
  }
  #newsSectionMobile{
    display: None;
  }
}
</style>
